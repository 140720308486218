@import "global";

.comment-viewer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.comment-viewer-item {
    display: flex;
    flex-direction: column;
    width: 90vw;
    padding: 10px;
    margin-bottom: 10px;
    background-color: $TC-dark-blue;
    color: $TC-bright-text;
    border: 5px solid;
    border-color: $TC-green;
    border-radius: 10px;
    font-size: 24px;
    align-items: flex-start;
    text-align: left;
}

.comment-viewer-item-read {
    border-color: $TC-blue;
    background-color: $TC-slightly-less-dark-blue;
}

.comment-viewer-button {
    background-color: $TC-dark-blue;
    color: $TC-bright-text;
    font-size: 24px;
    padding: 10px;
    border: 5px solid;
    border-color: $TC-blue;
    border-radius: 10px;
    margin-right: 10px;
    margin-left: 10px;

    &:hover {
        background-color: $TC-gray-dark;
    }

    &:disabled {
        background-color: $TC-gray-dark;
    }
}

.comment-viewer-split-screen {
    display: flex;
    justify-content: space-between;
    width: 90vw;
    text-align: left;
}

.comment-viewer-loading {
    font-size: 24px;
    font-family: fatima;
}

.comment-viewer-dropdown-select {
    margin-bottom: 10px;
    width: 250px !important;
    position: relative;
    left: 20px;
}

.comment-viewer-no-messages {
    height: 300px;
    justify-content: center;
    align-items: center;
}

.comment-viewer-read-button {
    border: solid 2px;
    border-radius: 5px;
    border-color: $TC-dark-text;
    background-color: $TC-bright-text;
    color: $TC-dark-text;
    margin-top: 10px;
    padding: 10px;
    font-size: 20px;
    font-family: Fatima;

    &:hover {
        color: $TC-bright-text;
        background-color: $TC-dark-blue;
        border-color: $TC-bright-text;
    }
}