@import "global";

.specials-header {
    font-family: caveat-brush;
    font-size: 64px;
    padding-top: 50px;
    padding-bottom: 40px;
    background-color: $TC-dark-yellow;
    color: $TC-bright-text;
    margin-bottom: 40px;
}

.specials {
    .post {
        background-color: $TC-dark-blue;
        color: $TC-bright-text;
        font-family: caveat-brush;
        font-size: 24px;
        width: 291px;
        border: 5px solid;
        border-radius: 13px;
        padding-top: 10px;
        padding-bottom: 30px;
    
        .header {
            font-size: 64px;
            padding: 0px;
        }
    
        .header-wide {
            width: auto;
        }
    
        .header-yellow {
            color: $TC-yellow;
        }
    
        .header-blue {
            color: $TC-blue;
        }
    
        .header-green {
            color: $TC-green;
        }
    
        .header-red {
            color: $TC-red;
        }
    
        .break {
            padding: 0;
            margin: 0;
        }
    
        .text {
            padding: 0px;
            display: flex;
            justify-content: center;
        }
    }
    
    .post-wide {
        width: calc(320px + 4vw);
    }

    .post-widest {
        width: calc(530px + 4vw);
    }
    
    .border-yellow {
        border-color: $TC-yellow;
    }
    
    .border-blue {
        border-color: $TC-blue;
    }
    
    .border-green {
        border-color: $TC-green;
    }
    
    .border-red {
        border-color: $TC-red;
    }

    .horizontal-list {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .vertical-list {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .vertical-block {
        width: 4vw;
    }

    .horizontal-block {
        height: 2vh;
    }

    .specials-spacer {
        height: 170px;
    }

    .specials-banner {
        font-family: caveat-brush;
        font-size: 64px;
        padding-top: 50px;
        padding-bottom: 40px;
        background-color: $TC-green;
        color: $TC-bright-text;
    }

    .gray-background {
        padding-top: 50px;
        padding-bottom: 50px;
        background-color: $TC-slightly-less-dark-blue;
    }

    .specials-pdf-link {
        text-decoration: none;
        font-size: 24px;
        color: $TC-blue;

        &:hover {
            color: $TC-dark-blue;
        }
    }
}
