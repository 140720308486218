$TC-yellow: #FFD933;
$TC-green: #55DC48;
$TC-blue: #49CDFF;
$TC-ocean-blue: #0030cf;
$TC-dark-yellow: #ffae00;
$TC-red: #F62A66;
$TC-dark-red: #C4102D;
$TC-gray-dark: #343434;
$TC-dark-blue: #374955;
$TC-slightly-less-dark-blue: #4f7086;
$TC-dark-text: #000000;
$TC-bright-text: #FFFFFF;
@font-face {
    font-family: caveat-brush;
    src: url(../fonts/caveat-brush.ttf);
}
@font-face {
    font-family: fjord;
    src: url(../fonts/FjordOne-Regular.ttf);
}
@font-face {
    font-family: faustina;
    src: url(../fonts/Faustina-VariableFont_wght.ttf);
}