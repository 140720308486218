@import "global";

.home {
    .main {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;

        .column {
            display: flex;
            flex-direction: column;
        }
    }

    .post {
        background-color: $TC-dark-blue;
        color: $TC-bright-text;
        font-family: caveat-brush;
        font-size: 24px;
        width: 291px;
        border: 5px solid;
        border-radius: 13px;
        padding-top: 10px;
        padding-bottom: 30px;

        .header {
            font-size: 64px;
            padding: 0px;
        }
        
        .header-wide {
            width: auto;
        }

        .header-yellow {
            color: $TC-yellow;
        }

        .header-blue {
            color: $TC-blue;
        }

        .header-green {
            color: $TC-green;
        }

        .header-red {
            color: $TC-red;
        }

        .break {
            padding: 0;
            margin: 0;
        }

        .text {
            padding: 0px;
            display: flex;
            justify-content: center;
        }
    }

    .post-wide {
        width: calc(400px + 12vw);
    }

    .border-yellow {
        border-color: $TC-yellow;
    }

    .border-blue {
        border-color: $TC-blue;
    }

    .border-green {
        border-color: $TC-green;
    }

    .border-red {
        border-color: $TC-red;
    }

    .end {
        height: calc(20px + 1vh);
    }

    .est-title {
        padding-top: 10px;
        font-family: 'Fjord';
        font-size: 32px;
    }

    .nachos {
        border: 5px solid;
        border-radius: 10px;
        border-color: $TC-yellow;
    }

    .block {
        display: flex;
        width: 100vw;
        justify-content: center;
    }

    .link {
        display: block;
        color: $TC-yellow;
        text-decoration: none;

        &:hover {
            color: $TC-bright-text;
        }
    }
}

.header-mobile {
    font-size: 56px !important;
}

.post-mobile {
    width: calc(320px + 12vw) !important;

    @media only screen and (max-width: 360px) {
       width: 93vw !important; 
    }
}

.mobile {
    .logo {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}