@import 'global';

.hamburger {
    padding-top: 2.3vh;
    padding-bottom: 2.3vh;
    position: fixed;
    border: 0;
    top: 0;
    left: 0;
    background-color: transparent;
}

.menu {
    position: absolute;
    top: max(11vh, 67px);
    left: 0px;
    height: 89vh;
    width: 100vw;
    z-index: 2;

    .item {
        width: 100vw;
        background-color: $TC-gray-dark;
        color: $TC-yellow;
        font-family: caveat-brush;
        font-size: 24px;
        list-style: none;

        &:hover {
            background-color: #585858;
        }
    }

    .link {
        display: block;
        min-width: 100vw;
        padding-top: 3vh;
        padding-bottom: 3vh;
        color: $TC-yellow;
        text-decoration: none;

        &:visited {
            color: $TC-yellow;
        }
    }
}