@import "global";

.not-found {
    padding-top: 60px;
    font-size: 64px;
    font-family: caveat-brush;
}

.not-found-text {
    font-size: 18px;
    font-family: faustina;
}

.not-found-link {
    color: $TC-ocean-blue;
    text-decoration: underline;

    &:visited {
        color: $TC-ocean-blue;
    }

    &:hover {
        color: $TC-blue;
    }
}