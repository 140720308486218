@import "global";

.nav-bar {
    align-items: start;
    justify-content: left;
    width: 100vw;
    background-color: $TC-gray-dark;
    position: fixed;
    padding-top: max(4vh, 19px);
    padding-bottom: max(4vh, 19px);
    margin: 0;
    z-index: 2;

    .button {
        background-color: $TC-gray-dark;
        color: $TC-yellow;
        padding-left: 2vw;
        padding-right: 2vw;
        padding-top: inherit;
        padding-bottom: inherit;
        font-size: 24px;
        font-family: caveat-brush;
        text-decoration: none;
        border: 0;
        position: relative;
        left: 20px;

        &:hover {
            background-color: #585858;
        }
    }
}