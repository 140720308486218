@import "global";

.catering-header {
    font-family: caveat-brush;
    font-size: 64px;
    padding-top: 50px;
    padding-bottom: 40px;
    background-color: $TC-slightly-less-dark-blue;
    color: $TC-bright-text;
}

.catering {
    .horizontal-list {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .vertical-list {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .vertical-block {
        width: 4vw;
    }

    .horizontal-block {
        height: 2vh;
    }

    .catering-spacer {
        height: 170px;
    }

    .catering-banner {
        font-family: caveat-brush;
        font-size: 64px;
        padding-top: 50px;
        padding-bottom: 40px;
        background-color: $TC-green;
        color: $TC-bright-text;
    }

    .gray-dark-background {
        background-color: $TC-gray-dark;
    }

    .catering-pdf-link {
        text-decoration: none;
        font-size: 24px;
        color: $TC-blue;

        &:hover {
            color: $TC-dark-blue;
        }
    }

    .catering-pdf {
        width: 100vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}
