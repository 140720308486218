@import 'global';

.contact-us {
    .contact-us-header {
        color: $TC-bright-text;
        background-color: $TC-dark-red;
        padding-top: 40px;
        padding-bottom: 40px;
        font-family: caveat-brush;
        font-size: 64px;
        position: relative;
        top: -11px;
    }
    
    .contact-us-text {
        font-family: Faustina;
        font-size: 18px;
        padding-bottom: 20px;
    }

    .contact-us-box {
        width: 70vw;
        margin-left: 15vw;
        border: 5px solid;
        border-radius: 13px;
        color: $TC-green;
        background-color: $TC-dark-blue;
        padding-top: 15px;
        padding-bottom: 20px;
        display: flex;
        flex-direction: column;
        
        .contact-us-box-text {
            color: $TC-bright-text;
            font-family: Faustina;
            font-size: 24px;
            display: flex;
            padding-left: 15px;
            padding-top: 5px;
            padding-bottom: 5px;
        }

        .contact-us-box-red-text {
            color: $TC-red;
        }

        .contact-us-edit-box {
            font-size: 24px;
            background-color: inherit;
            border-width: 1px;
            border-style: solid;
            border-radius: 3px;
            border-color: $TC-bright-text;
            color: $TC-bright-text;

            &:focus-visible {
                outline: none;
            }
        }
        
        .contact-us-edit-box-width-small {
            display: block;
            max-width: 30vw;
            min-width: 1vw;
            width: 30vw;
        }

        .contact-us-edit-box-width-large {
            display: block;
            max-width: 40vw;
            min-width: 1vw;
            width: 40vw;
            margin-right: 20px;
        }

        .contact-us-edit-box-width-max {
            display: block;
            max-width: 70vw;
            min-width: 1vw;
            width: 70vw;
            margin-right: 20px;
        }

        .contact-us-edit-box-height-long {
            max-height: 25vh;
            min-height: 1vh;
            height: 25vh;
            
        }

        .contact-us-edit-field {
            display: flex;
            padding-left: 20px;
        }

        .contact-us-break {
            width: 20px;
        }
    }

    .contact-us-submit-button {
        background-color: $TC-bright-text;
        color: $TC-dark-text;
        border-color: $TC-dark-text;
        margin-top: 10px;
        border-style: solid;
        border-radius: 4px;
        text-decoration: none;
        padding: 10px;
        font-size: 24px;
        font-style: normal;
        font-family: Faustina;

        &:hover {
            background-color: $TC-dark-blue;
            color: $TC-bright-text;
            border-color: $TC-bright-text;
        }

        &:disabled {
            background-color: $TC-dark-blue;
            color: $TC-dark-text;
            border-color: $TC-dark-text;

            &:hover {
                background-color: $TC-dark-blue;
                color: $TC-dark-text;
                border-color: $TC-dark-text;
            }
        }
    }
}