@import "global";

.banner {
    text-align: center;
    vertical-align: center;
    width: 100vw;
    padding-top: 1.5vh;
    padding-bottom: 1.5vh;
    background-color: $TC-dark-yellow;
    color: $TC-bright-text;
    position: relative;
    top: -11px;
    font-weight: 600;
    font-size: 20px;
}