@import "global";

.about-us-header {
    font-family: caveat-brush;
    font-size: 64px;
    padding-top: 50px;
    padding-bottom: 40px;
    background-color: $TC-blue;
    color: $TC-bright-text;
    margin-bottom: 40px;
}

.about-us-body {
    text-indent: 50px;
    margin-left: 10vw;
    margin-right: 10vw;
    text-align: left;
    font-size: 24px;
}

.about-us-footer {
    margin-top: 60px;
}