@import "global";

.app {
  text-align: center;

  .body {
    position: relative;
    top: calc(20px + 8vh);
    padding-bottom: 1em; // possibly modify in future
  }

  .footer {
    position: fixed;
    bottom: 0;
    width: 100vw;
    background-color: $TC-gray-dark;
    color: $TC-bright-text;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
  }
}
