@import 'global';

textarea,
input[type="file"] {
    display: none;
}

.employment {
    .employment-header {
        color: $TC-bright-text;
        background-color: $TC-green;
        padding-top: 40px;
        padding-bottom: 40px;
        font-family: caveat-brush;
        font-size: 64px;
        position: relative;
        top: -11px;
    }

    .employment-box {
        width: 70vw;
        margin-left: 15vw;
        border: 5px solid;
        border-radius: 13px;
        color: $TC-red;
        background-color: $TC-dark-blue;
        padding-top: 15px;
        padding-bottom: 20px;
        display: flex;
        flex-direction: column;

        .employment-box-text {
            color: $TC-bright-text;
            font-family: Faustina;
            font-size: 24px;
            display: flex;
            padding-left: 15px;
            padding-right: 15px;
            padding-top: 5px;
            padding-bottom: 5px;
            text-align: left;
        }

        .employment-box-red-text {
            color: $TC-red;
        }

        .employment-edit-box {
            font-size: 24px;
            background-color: inherit;
            border-width: 1px;
            border-style: solid;
            border-radius: 3px;
            border-color: $TC-bright-text;
            color: $TC-bright-text;

            &:focus-visible {
                outline: none;
            }
        }

        .employment-edit-box-width-small {
            display: block;
            max-width: 30vw;
            min-width: 1vw;
            width: 30vw;
        }

        .employment-edit-box-width-large {
            display: block;
            max-width: 40vw;
            min-width: 1vw;
            width: 40vw;
            margin-right: 20px;
        }

        .employment-edit-box-width-max {
            display: block;
            max-width: 70vw;
            min-width: 1vw;
            width: 70vw;
            margin-right: 20px;
        }

        .employment-edit-box-height-long {
            max-height: 25vh;
            min-height: 1vh;
            height: 25vh;
        }

        .employment-edit-box-text-area {
            display: block;
            min-width: 60vw;
            width: 70vw;
            margin-right: 20px;
            min-height: 15vh;
            height: 15vh;
        }

        .employment-date-input {
            position: relative;
            top: -3px;
        }

        .employment-edit-field {
            display: flex;
            padding-left: 20px;
        }

        .employment-break {
            width: 20px;
        }

        .employment-file-upload {
            color: $TC-dark-text;
            font-size: 24px;
            border: 2px solid;
            border-color: $TC-dark-text;
            border-radius: 4px;
            padding: 10px;
            background-color: $TC-bright-text;

            &:hover {
                color: $TC-bright-text;
                background-color: $TC-dark-blue;
                border-color: $TC-bright-text;
            }
        }

        .employment-file-list {
            color: $TC-bright-text;
            font-size: 16px;
            text-align: start;
            margin-left: 20px;
            margin-right: 20px;
        }
    }

    .employment-pdf {
        display: flex;
        justify-content: center;
        margin-top: 10px;
    }

    .employment-pdf-remove-button {
        background-color: $TC-dark-blue;
        border: none;
        height: 40px;
        position: relative;
        top: 4px;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 60vw;
    }

    .employment-pdf-remove-icon {
        color: $TC-bright-text;
    }

    .employment-pdf-button {
        color: $TC-dark-text;
        background-color: $TC-bright-text;
        border-color: $TC-dark-text;
        border-width: 2px;
        border-style: solid;
        border-radius: 4px;
        font-size: 18px;
        font-family: faustina;
        padding: 10px;
        margin-top: 5px;

        &:hover {
            color: $TC-bright-text;
            background-color:  $TC-dark-blue;
            border-color: $TC-bright-text;
        }
    }

    .employment-box-job-select {
        margin-left: 20px;
        margin-right: 20px;
    }

    .employment-box-job-dropdown {
        border-color: $TC-bright-text;
        color: $TC-bright-text;
        border-style: solid;
        border-radius: 4px;
        border-width: 1px;

        &:hover {
            border-color: $TC-bright-text;
        }

        &:focus-within {
            border-color: $TC-bright-text;
        }
    }

    .css-db0ja4-DropDown {
        background-color: $TC-bright-text;
        color: $TC-dark-text;
        border-color: $TC-dark-text;
        border-width: 2px;
        border-radius: 4px;
    }

    .css-nfp1ut {
        color: $TC-dark-text;
    }

    .gAhpPE {
        color: $TC-bright-text;
        font-size: 24px;
    }

    .kbQiGj {
        color: $TC-bright-text;
        font-size: 24px;
    }

    .css-z2hyoe-ItemComponent {
        color: $TC-dark-text;
    }

    .employment-schedule-container {
        padding-left: 30px;
        padding-right: 30px;
    }
    
    .employment-schedule {
        overflow: auto;
        padding-right: 50px;
    }

    .DfzEq {
        color: $TC-bright-text;
        font-size: 24px;
        font-style: faustina;
    }

    .cVtHuD {
        color: $TC-bright-text;
        font-size: 24px;
        font-style: faustina;
    }

    .employment-schedule-dropdown {
        background-color: $TC-bright-text;
        color: $TC-dark-text;
        padding: 10px;
        font-size: 24px;
        text-decoration: none;
        border-radius: 4px;
        border-style: solid;
        border-color: $TC-dark-text;
        margin-bottom: 10px;
        display: block;
        margin-left: 20px;

        &:hover {
            color: $TC-bright-text;
            background-color: $TC-dark-blue;
            border-color: $TC-bright-text;
        }
    }

    .employment-submit-button {
        background-color: $TC-bright-text;
        color: $TC-dark-text;
        border-color: $TC-dark-text;
        margin-top: 10px;
        border-style: solid;
        border-radius: 4px;
        text-decoration: none;
        padding: 10px;
        font-size: 24px;

        &:hover {
            background-color: $TC-dark-blue;
            color: $TC-bright-text;
            border-color: $TC-bright-text;
        }

        &:disabled {
            background-color: $TC-dark-blue;
            color: $TC-dark-text;
            border-color: $TC-dark-text;

            &:hover {
                background-color: $TC-dark-blue;
                color: $TC-dark-text;
                border-color: $TC-dark-text;
            }
        }
    }

    .employment-confirmation-pdf {
        margin-top: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        
    }
}

