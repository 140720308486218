@import 'global';

.menu-pdf {
  width: auto;
  position: relative;
  top: auto;
  left: auto;
  height: auto;
  background-color: black;
  overflow-x: scroll;
}

.menu-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.desktop {
  display: flex;
  justify-content: center;
}

.react-pdf__Page__annotations.annotationLayer {
  height: auto !important;
}

.react-pdf__message--error {
  padding-top: 20px;
  color: $TC-bright-text;
  height: 84vh;
}